import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import merge from "lodash.merge";
import { makeStyles, Typography, Theme } from "@material-ui/core";
import { goBack } from "../../../utils/navigation";
import { useBrand } from "../../common/BrandProvider/BrandProvider";
import Button from "../../common/Button/Button";
import ContentWrapper from "../../common/ContentWrapper/ContentWrapper";
import Titlebar from "../../common/Titlebar/Titlebar";
import { useTealium } from "../../common/TealiumProvider/TealiumProvider";
import { capitalizeEachWordOfPathname } from "../../../utils/capitalisePathname";
import { ChangeInstructorProps } from "./types";
import clsx from "clsx";
import { Brand } from "@graphql/types";

interface CustomTheme extends Theme {
  journey: {
    RUJChangeInstructor: Record<string, any>;
  };
}

const useStyles = makeStyles<CustomTheme>(theme =>
  merge(theme.journey.RUJChangeInstructor, {
    subtitleText: {
      fontWeight: "bold",
    },
    bodyText: {
      margin: "16px 0",
      "@media(min-width: 1024px)": {
        margin: "24px 0",
      },
    },
    bodyText2: {
      color: theme.palette.grey[400],
      opacity: 0.6,
    },
    labelText: {
      marginBottom: "0.5em",
    },
    buttonWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    button: {
      margin: "2em 0em 3em",
    },
    actionButton: {
      margin: "0 auto",
      "&.bsm": {
        width: "226px",
      },
    },
    contentWrapper: {
      paddingTop: "36px",
      "@media(min-width: 1024px)": {
        paddingTop: "48px",
      },
    },
  }),
);

const ChangeInstructor: React.FC<ChangeInstructorProps> = ({
  history,
  location: { pathname },
}) => {
  const { brand, getText } = useBrand();
  const classes = useStyles();
  const { trackEvent, updateDataLayer } = useTealium();

  const handleOpenChat = () => {
    trackEvent({
      eventCategory: capitalizeEachWordOfPathname(pathname),
      eventAction: "Button Click",
      eventLabel: "Start A Chat",
    });
    updateDataLayer({
      chat_started: "true",
    });
  };

  return (
    <Fragment>
      <ContentWrapper>
        <Titlebar
          showBackButton
          onBackClick={() => goBack({ history, pathname })}
          headingProps={{ "data-test": "ruj-change-instructor-title-bar" }}
        >
          {getText("returning/change-instructor.pageTitle")}
        </Titlebar>
        <div>
          <Typography component="p" className={classes.subtitleText}>
            {getText("returning/change-instructor.subTitle")}
          </Typography>

          <Typography component="p" className={classes.bodyText}>
            {getText("returning/change-instructor.bodyText")}
          </Typography>
          <Typography component="p" className={classes.bodyText}>
            {getText("returning/change-instructor.bodyText2")}
          </Typography>
          <Typography component="p" className={classes.bodyText2}>
            {getText("returning/change-instructor.bodyTextHelper")}
          </Typography>

          <ContentWrapper classes={{ wrapper: classes.contentWrapper }}>
            <Button
              className={clsx(classes.actionButton, {
                bsm: brand === Brand.Bsm,
              })}
              onClick={handleOpenChat}
              data-chat={false}
              text={"Start a chat"}
              data-test="success-action-button"
            />
          </ContentWrapper>
        </div>
      </ContentWrapper>
    </Fragment>
  );
};

export default withRouter(ChangeInstructor);
